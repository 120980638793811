import { PhoneCase } from '@qcases/common';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { HttpRequest } from 'domain/HttpRequest.model';

export interface ListingPageState {
  apiCall: HttpRequest<PhoneCase[], any>;
}

const initialState: ListingPageState = {
  apiCall: {
    loading: true,
    error: null,
    data: null,
  },
};

export const listingPageSlice = createSlice({
  name: 'listingPage',
  initialState,
  reducers: {
    fetchProducts: state => {
      state.apiCall.loading = true;
    },
    fetchProductsFailed: (state, action: PayloadAction<any>) => {
      state.apiCall.error = action.payload;
      state.apiCall.loading = false;
    },
    fetchProductsSuccess: (state, action: PayloadAction<PhoneCase[]>) => {
      state.apiCall.data = action.payload;
      state.apiCall.loading = false;
    },
  },
});

// Action creators are generated for each case reducer function
export const { fetchProducts, fetchProductsFailed, fetchProductsSuccess } =
  listingPageSlice.actions;

export const { reducer: listingPageReducer } = listingPageSlice;
