import React, { useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../application/root.reducer';
import './CaseDesigner.scss';
import { SquareItem } from './designerPadGrid/SquareItem';
import './DesignerPad.scss';
import { InplaceImageEditor } from './designerPadGrid/inplaceImageEditor/InplaceImageEditor';
import {
  dragSticker,
  IconListState,
} from '../application/IconList/IconList.slice';
import { StickerDropArea } from './designerPadGrid/StickerDropArea/StickerDropArea';
import { AddedStickersList } from './designerPadGrid/AddedStickersList/AddedStickersList';
import { OutputPhoneCasePreview } from '@qcases/common-ui';
import { ContainedBoxMetric, PhoneCase } from '@qcases/common';
import { CaseDesignerState } from 'application/CaseDesigner/CaseDesigner.slice';

export const DesignerPad: React.FC<{
  phoneCase: PhoneCase;
  exportResultRef: any;
}> = ({ phoneCase, exportResultRef }) => {
  const { exactBoundary } = phoneCase;
  const [displayRatio, setDisplayRatio] = useState(1);
  const { width, height } = exactBoundary;
  const { backgroundImageEditor, backgroundImageResult, isEditing } =
    useSelector<RootState, CaseDesignerState>(state => state.caseDesigner);
  const { addedStickers } = useSelector<RootState, IconListState>(
    state => state.iconList
  );
  const dispatch = useDispatch();

  const onChangeRenderRatio = useCallback((newRatio: number) => {
    setDisplayRatio(newRatio);
  }, []);

  const onStickerDragging = useCallback(
    (isDragging: boolean) => {
      dispatch(dragSticker(isDragging));
    },
    [dispatch]
  );
  const calculatedMetrics = useMemo<ContainedBoxMetric>(() => {
    return {
      width: exactBoundary.width * displayRatio,
      height: exactBoundary.height * displayRatio,
      x: -displayRatio,
      y: -displayRatio,
      borderTopLeftRadius: exactBoundary.borderTopLeftRadius * displayRatio,
      borderTopRightRadius: exactBoundary.borderTopRightRadius * displayRatio,
      borderBottomLeftRadius:
        exactBoundary.borderBottomLeftRadius * displayRatio,
      borderBottomRightRadius:
        exactBoundary.borderBottomRightRadius * displayRatio,
    };
  }, [displayRatio, exactBoundary]);

  return (
    <div
      id="designerPadContainer"
      style={{
        position: 'relative',
      }}
    >
      {backgroundImageEditor && backgroundImageEditor?.imageUrl && (
        <div
          id="imageEditAreaContainer"
          style={{
            width: width * displayRatio,
            height: height * displayRatio,
          }}
        ></div>
      )}
      {!phoneCase ? null : (
        <OutputPhoneCasePreview
          elementId={'resultPhoneCase'}
          exactBoundaryRef={exportResultRef}
          phoneCase={phoneCase}
          ratio={displayRatio}
          exactBoundaryHiddenOverflow={!isEditing}
          onChangeRenderRatio={onChangeRenderRatio}
        >
          <>
            <SquareItem
              itemData={backgroundImageResult}
              calculatedMetrics={calculatedMetrics}
              isEditing={isEditing}
            />

            {/* This place for dropped stickers */}
            <AddedStickersList
              stickers={addedStickers}
              displayRatio={displayRatio}
              onStickerDragging={onStickerDragging}
            />
            <StickerDropArea
              calculatedMetrics={calculatedMetrics}
              displayRatio={displayRatio}
            />
            {!isEditing ? null : (
              <InplaceImageEditor
                editingImage={backgroundImageEditor!}
                phoneCaseExactBoundary={exactBoundary}
                displayRatio={displayRatio}
              />
            )}
          </>
        </OutputPhoneCasePreview>
      )}
    </div>
  );
};
