export const EDITOR_BORDER_IN_PX = 50;

export const STICKER_DRAG_DROP_DATA_TRANSFER_KEY = 'draggingItemId';

export enum DragDropItemTypes {
  ADDED_STICKER = 'ADDED_STICKER',
  NEW_STICKER = 'NEW_STICKER',
}

export const DEFAULT_ADDED_STICKER_SIZE = 30;
