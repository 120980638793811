import React, { useEffect } from 'react';
import { Col, Container, ListGroup, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { fetchProductDetail } from '../../../application/ProductDetail/ProductDetail.slice';
import { RootState } from '../../../application/root.reducer';
import { HttpRequest } from '../../../domain/HttpRequest.model';
import { CaseDesigner } from '../../CaseDesigner';
import AppBanner from '../../_components/AppBanner';
import { RawStickerList } from '../../editingFeatures/RawStickerList/RawStickerList';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { BackgroundImageGenerator } from 'view/editingFeatures/BackgroundImageGenerator/BackgroundImageGenerator';
import { PhoneCase } from '@qcases/common';

export const ProductDetail: React.FC = () => {
  // Currently renders twice, need to investigate later
  const { productName } = useParams<any>();
  const dispatch = useDispatch();
  const productDetailApicall = useSelector<
    RootState,
    HttpRequest<PhoneCase, any>
  >(state => state.productDetail.apiCall);
  useEffect(() => {
    if (!productName) {
      return;
    }
    dispatch(fetchProductDetail(productName as string));
  }, [productName]);
  if (productDetailApicall.loading) {
    return <div>Loading</div>;
  }
  if (productDetailApicall.error) {
    return <div>Error page</div>;
  }
  const { data } = productDetailApicall;
  return (
    <>
      <AppBanner title={'Phone cases'} subtitle={'Phone cases list'} />

      <DndProvider backend={HTML5Backend}>
        <Container className="productDetail">
          <Row className="productDetail__infoArea">
            <Col className="proaductDetail__infoArea pt-4" xl="4">
              <BackgroundImageGenerator />
              <RawStickerList />
              <ListGroup className="mb-4">
                <ListGroup.Item>
                  Select the grid item and drop image to the area to add
                </ListGroup.Item>
                <ListGroup.Item>
                  <b>Rotate</b>, <b>Zoom</b> to adjust image
                </ListGroup.Item>
                <ListGroup.Item>
                  <b>Delete</b> to clear, <b>Reset</b> to reset to initial state
                  before editing
                </ListGroup.Item>
                <ListGroup.Item>
                  <b>Click out side the current editing area</b> to save
                  changes/discard editing grid item&apos;s image
                </ListGroup.Item>
              </ListGroup>
            </Col>
            <Col className="productDetail__designArea" xl="8">
              <Container>
                <Row>
                  <Col
                    xl={{
                      span: 6,
                      offset: 3,
                    }}
                  >
                    <CaseDesigner phoneCase={data!} />
                  </Col>
                </Row>
              </Container>
            </Col>
          </Row>
        </Container>
      </DndProvider>
    </>
  );
};
