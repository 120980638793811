import { call, put, takeLatest } from 'redux-saga/effects';
import { Icon, StickerQueryModel } from 'domain/BackEnd.model';
import {
  fetchIcons,
  fetchIconsFailed,
  fetchIconsSuccess,
} from './IconList.slice';
import { getIcons } from './IconListApi.service';
import { PayloadAction } from '@reduxjs/toolkit';

// worker Saga: will be fired on USER_FETCH_REQUESTED actions
function* fetchIconsData(action: PayloadAction<StickerQueryModel>) {
  try {
    const icons: Icon[] = yield call(getIcons, action.payload.q);
    yield put(fetchIconsSuccess({ icons, query: action.payload }));
  } catch (e) {
    yield put(fetchIconsFailed((e as any).message as string));
  }
}

export function* iconListSaga() {
  yield takeLatest(fetchIcons.type, fetchIconsData);
}
