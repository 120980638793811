import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { BackgroundImage, Collection } from 'domain/backgroundImage/BackgroundImage.model';

export interface BackgroundImageState {
  loading: boolean;
  error: string;
  collection: Collection | null;
  selectedImage: BackgroundImage | null;
}

const initialState: BackgroundImageState = {
  loading: false,
  error: '',
  collection: null,
  selectedImage: null
};
export const BackgroundImageListSlice = createSlice({
  name: 'backgroundImage',
  initialState,
  reducers: {
    fetchCollection: (state, action: PayloadAction<{ prompt: string }>) => {
      state.loading = true;
      state.error = '';
    },
    fetchCollectionSuccess: (
      state,
      action: PayloadAction<{ collection: Collection }>
    ) => {
      state.collection = action.payload.collection;
      state.loading = false;
      state.error = '';
    },
    fetchCollectionFailed: (state, action: PayloadAction<string>) => {
      state.loading = false;
      state.error = action.payload;
    },
    selectImage: (state, action: PayloadAction<BackgroundImage>) => {
      state.selectedImage = action.payload;
    },
  },
});

export const {
  fetchCollection,
  fetchCollectionSuccess,
  fetchCollectionFailed,
  selectImage
} = BackgroundImageListSlice.actions;

export const { reducer: backgroundImageReducer } = BackgroundImageListSlice;
