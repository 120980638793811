import axios from 'axios';
import { BASE_API_URL } from '../../domain/constants/api';
import { Collection } from 'domain/backgroundImage/BackgroundImage.model';

function getCollection(prompt: string) {
  return axios
    .get<Collection>(`${BASE_API_URL}/background-lib/collections`, {
      params: {
        prompt
      },
    })
    .then(res => res.data);
}

export { getCollection };
