
import { AddedSticker } from 'domain/BackEnd.model';
import React from 'react';
import { AddedStickerComp } from './AddedSticker';

export const AddedStickersList: React.FC<{
  stickers: AddedSticker[];
  displayRatio: number;
  onStickerDragging: (isDragging: boolean) => void;
}> = ({ stickers, displayRatio, onStickerDragging }) => {
  return !stickers.length ? null : (
    <>
      {stickers.map(s => (
        <AddedStickerComp
          key={s.clientSideId}
          sticker={s}
          displayRatio={displayRatio}
          onDrag={onStickerDragging}
        />
      ))}
    </>
  );
};
