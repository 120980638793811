import { PhoneCase } from '@qcases/common';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { HttpRequest } from '../../domain/HttpRequest.model';

export interface ProductDetailState {
  apiCall: HttpRequest<PhoneCase, any>;
}

const initialState: ProductDetailState = {
  apiCall: {
    loading: true,
    error: null,
    data: null,
  },
};

export const productDetailSlice = createSlice({
  name: 'productDetail',
  initialState,
  reducers: {
    fetchProductDetail: (state, action: PayloadAction<string>) => {
      state.apiCall.loading = true;
    },
    fetchProductDetailFailed: (state, action: PayloadAction<any>) => {
      state.apiCall.error = action.payload;
      state.apiCall.loading = false;
    },
    fetchProductDetailSuccess: (state, action: PayloadAction<PhoneCase>) => {
      state.apiCall.data = action.payload;
      state.apiCall.loading = false;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  fetchProductDetail,
  fetchProductDetailFailed,
  fetchProductDetailSuccess,
} = productDetailSlice.actions;

export const { reducer: productDetailReducer } = productDetailSlice;
