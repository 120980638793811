import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import './BackgroundImageGenerator.scss';
import { MDBInput } from 'mdb-react-ui-kit';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'application/root.reducer';
import { BackgroundImageState, fetchCollection, selectImage } from 'application/BackgroundImage/BackgroundImage.slice';
import { CommonSpinner } from 'view/_components/CommonSpinner';
import { BackgroundImageItem } from './BackgroundImageItem';
import _ from 'lodash';
import { BackgroundImage } from 'domain/backgroundImage/BackgroundImage.model';
import { draftEditImageData, editBackgroundImage } from 'application/CaseDesigner/CaseDesigner.slice';

const BackgroundImageGeneratorComp: React.FC<{
}> = () => {
  const { collection, loading, error, selectedImage } = useSelector<
    RootState,
    BackgroundImageState
  >(state => state.backgroundImage);
  const dispatch = useDispatch();
  const [prompt, setPrompt] = useState('');
  useEffect(() => {
    if (!prompt) {
      return;
    }
    dispatch(fetchCollection({ prompt }));
  }, [dispatch, prompt]);
  const onPromptChange = _.debounce(event => {
    setPrompt(event.target.value);
  }, 1000);

  useEffect(() => {
    if (!selectedImage) {
      return;
    }
    dispatch(editBackgroundImage());
    dispatch(draftEditImageData({
      imageUrl: selectedImage.url,
      rotationAngle: 0,
      x: 0,
      y: 0,
      zoom: 1
    }));
  }, [selectedImage]);

  const onBackgroundImageClicked = (image: BackgroundImage) => {
    dispatch(selectImage(image));
  }
  return (<Container className="BackgroundImageGenerator pb-4">
    <h2>Generate background image</h2>
    <Row className="BackgroundImageGenerator__PromptArea mb-4">
      <MDBInput label={'Create creative background image with AI'} onChange={onPromptChange} />
    </Row>
    <Row className="BackgroundImageGenerator__List pb-4">
      <Col>
        {loading ? (
          <CommonSpinner />
        ) : error ? (
          'Error'
        ) : (
          <Container fluid>
            <Row>
              {!collection?.images || !collection?.images.length
                ? (prompt ? 'Cannot generate or find any images with prompt now'
                  : 'Generate image with prompt')
                : collection?.images.map(image => {
                  return (
                    <BackgroundImageItem
                      key={image.id}
                      selected={image.id === selectedImage?.id}
                      image={image}
                      onBackgroundImageClicked={onBackgroundImageClicked}
                    />
                  );
                })}
            </Row>
          </Container>
        )}
      </Col>
    </Row>
  </Container>
  );
};

export const BackgroundImageGenerator = React.memo(BackgroundImageGeneratorComp);
