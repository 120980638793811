import { Button, Col, Container, Form, Row } from 'react-bootstrap';
import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import AvatarEditor from 'react-avatar-editor';
import Dropzone from 'react-dropzone';
import { ImageData } from '../../domain/SquareItem.model';
import { EDITOR_BORDER_IN_PX } from '../../domain/constants/ui';
import './CanvasEditor.scss';
// https://react-icons.github.io/react-icons/icons?name=fa
import { FaTrashAlt, FaUndoAlt } from 'react-icons/fa';
import { checkIfIsElementClicked } from '../../utils/dom';
import { ContainedBoxMetric } from '@qcases/common';

export const CanvasEditor: React.FC<{
  calculatedMetric: ContainedBoxMetric;
  imageData: ImageData;
  onSave: (editedImageData: ImageData) => void;
  onDraftChange: (editedImageData: ImageData) => void;
  onClear: () => void;
  onReset: () => void;
}> = ({
  imageData,
  calculatedMetric,
  onSave,
  onDraftChange,
  onClear,
  onReset,
}) => {
  const { width, height, x, y, borderTopLeftRadius } = calculatedMetric;
  const editor = useRef<any>(null);
  const editorContainerRef = useRef<any>(null);

  const { imageUrl } = imageData;
  // Need to convert to base64 because canvas not allowed export image loaded cross origin
  // const [imageParsedToBase64, setImageParsedToBase64] = useState("");
  const [buttonIdToDataMap] = useState<{
    [id: string]: {
      btnTitle: string;
      variant: string;
      btnIconComponent: any;
    };
  }>({
    editorBtnClear: {
      btnIconComponent: <FaTrashAlt />,
      btnTitle: 'Clear',
      variant: 'danger',
    },
    editorBtnReset: {
      btnIconComponent: <FaUndoAlt />,
      btnTitle: 'Reset',
      variant: 'danger',
    },
  });

  useEffect(() => {
    (editorContainerRef!.current as HTMLDivElement).onclick = event => {
      const isClickedToActionsButton = Object.keys(buttonIdToDataMap).some(
        key => checkIfIsElementClicked(key, event.target!)
      );
      if (isClickedToActionsButton) {
        return;
      }
      event.stopPropagation();
    };
    const onBodyMouseDownHandler = event => {
      const isClickedToActionsButton = Object.keys(buttonIdToDataMap).some(
        key => checkIfIsElementClicked(key, event.target!)
      );
      if (isClickedToActionsButton) {
        return;
      }
      // Save changes
      try {
        onSave({
          ...imageData,
          outputBase64ImageUrl: editor.current.getImage().toDataURL(),
        });
      } catch (error) {
        onSave({
          ...imageData,
          outputBase64ImageUrl: '',
        });
      }
    };
    document.body.addEventListener('click', onBodyMouseDownHandler);
    return () => {
      document.body.removeEventListener('click', onBodyMouseDownHandler);
      if (editorContainerRef.current) {
        console.log('editorContainerRef.current not null');
      }
      editorContainerRef.current &&
        ((editorContainerRef!.current as HTMLDivElement).onclick = null);
    };
  }, [onSave, imageData, buttonIdToDataMap]);
  const onImageDrop = async (acceptedFiles: any[]) => {
    onDraftChange({
      ...imageData,
      imageUrl: window.URL.createObjectURL(acceptedFiles[0]),
    });
  };
  const onImageZoomChange = (event: ChangeEvent<HTMLInputElement>) => {
    onDraftChange({
      ...imageData,
      zoom: Number(event.target.value),
    });
  };
  const onPositionChange = event => {
    onDraftChange({
      ...imageData,
      x: event.x,
      y: event.y,
    });
  };
  const onRotateAngleChange = (event: ChangeEvent<HTMLInputElement>) => {
    onDraftChange({
      ...imageData,
      rotationAngle: Number(event.target.value),
    });
  };

  // Usage
  return (
    <div
      ref={editorContainerRef}
      className="canvasEditor"
      style={{
        width: `${width}px)`,
        height: `${height}px)`,
        left: `${x}px`,
        top: `${y}px`,
      }}
    >
      <>
        <Dropzone onDrop={onImageDrop} noClick noKeyboard>
          {({ getRootProps, getInputProps }) => (
            <div {...getRootProps()}>
              <AvatarEditor
                ref={editor}
                crossOrigin="anonymous"
                image={imageUrl}
                position={{
                  x: imageData.x,
                  y: imageData.y,
                }}
                width={width}
                height={height}
                border={EDITOR_BORDER_IN_PX}
                // Now only support 1 border radius for all corners
                // In the future, maybe the library updated then can support different
                // border radius for each corner
                borderRadius={borderTopLeftRadius}
                color={[255, 255, 255, 0.6]} // RGBA
                scale={imageData.zoom}
                rotate={imageData.rotationAngle}
                onPositionChange={onPositionChange}
              />
              <input {...getInputProps()} />
            </div>
          )}
        </Dropzone>

        <Container className="canvasEditor__actionsButtons pt-1 pb-3">
          <Row>
            <Col xl={4}>
              <Form.Label>Rotate</Form.Label>
            </Col>
            <Col xl={8}>
              <Form.Range
                min="0"
                max="360"
                step="1"
                value={imageData.rotationAngle}
                onChange={onRotateAngleChange}
              />
            </Col>
          </Row>
          <Row>
            <Col xl={4}>
              <Form.Label>Zoom</Form.Label>
            </Col>
            <Col xl={8}>
              <Form.Range
                min="1"
                max="5"
                step="0.05"
                value={imageData.zoom}
                onChange={onImageZoomChange}
              />
            </Col>
          </Row>
          <Row>
            <Col xxl={6} xl={6} md={6} sm={6} xs={6}>
              <Button
                id="editorBtnClear"
                variant={buttonIdToDataMap['editorBtnClear'].variant}
                title={buttonIdToDataMap['editorBtnClear'].btnTitle}
                onClick={onClear}
              >
                {buttonIdToDataMap['editorBtnClear'].btnIconComponent}
              </Button>
            </Col>
            <Col xxl={6} xl={6} md={6} sm={6} xs={6}>
              <Button
                id="editorBtnReset"
                variant={buttonIdToDataMap['editorBtnReset'].variant}
                title={buttonIdToDataMap['editorBtnReset'].btnTitle}
                onClick={onReset}
              >
                {buttonIdToDataMap['editorBtnReset'].btnIconComponent}
              </Button>
            </Col>
          </Row>
        </Container>
      </>
    </div>
  );
};
