import { call, put, takeLatest } from 'redux-saga/effects';
import { Icon, StickerQueryModel } from 'domain/BackEnd.model';
import {
  fetchCollection,
  fetchCollectionFailed,
  fetchCollectionSuccess,
} from './BackgroundImage.slice';
import { getCollection } from './BackgroundImageApi.service';
import { PayloadAction } from '@reduxjs/toolkit';

// worker Saga: will be fired on USER_FETCH_REQUESTED actions
function* fetchCollectionsData(action: PayloadAction<{ prompt }>) {
  try {
    const collection = yield call(getCollection, action.payload.prompt);
    yield put(fetchCollectionSuccess({ collection }));
  } catch (e) {
    yield put(fetchCollectionFailed((e as any).message as string));
  }
}

export function* backgroundImageSaga() {
  yield takeLatest(fetchCollection.type, fetchCollectionsData);
}
