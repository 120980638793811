import { PhoneCase } from '@qcases/common';
import { call, put, takeLatest } from 'redux-saga/effects';
import {
  fetchProductDetail,
  fetchProductDetailFailed,
  fetchProductDetailSuccess,
} from './ProductDetail.slice';
import { getProductDetail } from './ProductDetailApi.service';

// worker Saga: will be fired on USER_FETCH_REQUESTED actions
function* fetchProductDetailData(action) {
  try {
    const productDetail: PhoneCase = yield call(
      getProductDetail,
      action.payload
    );
    yield put(fetchProductDetailSuccess(productDetail));
  } catch (e) {
    yield put(
      fetchProductDetailFailed((e as unknown as any).message as string)
    );
  }
}
/*
  Starts fetchUser on each dispatched `USER_FETCH_REQUESTED` action.
  Allows concurrent fetches of user.
*/
export function* productDetailSaga() {
  yield takeLatest(fetchProductDetail.type, fetchProductDetailData);
}
