import React from 'react';
import { Col } from 'react-bootstrap';
import './BackgroundImageItem.scss';
import { BackgroundImage } from 'domain/backgroundImage/BackgroundImage.model';
import { MDBRipple } from 'mdb-react-ui-kit';

const BackgroundImageItemInner: React.FC<{
    image: BackgroundImage;
    selected: boolean;
    onBackgroundImageClicked: (image: BackgroundImage) => void;
}> = ({ image, selected, onBackgroundImageClicked }) => {

    const onBackgroundImageClick = () => {
        onBackgroundImageClicked(image);
    };

    return (
        <Col
            xl={6}
            className={`BackgroundImageItem text-center mb-4 ${!selected ? '' : 'BackgroundImageItem--selected'}`}
            onClick={onBackgroundImageClick}
        >
            <MDBRipple rippleTag='a'>
                <img className='BackgroundImageItem__Image img-thumbnail'
                    id={`${image.id}`} src={image.smallUrl} alt="" />
                <img className='BackgroundImageItem__PreviewImage' id={`${image.id}_preview`}
                    src={image.mediumUrl} alt="" />
            </MDBRipple>
        </Col>
    );
};

export const BackgroundImageItem = React.memo(BackgroundImageItemInner);
