import React, { useCallback, useLayoutEffect, useMemo, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { ImageData, SquareItemModel } from '../../../domain/SquareItem.model';
import { CanvasEditor } from '../../svgImageEditor/CanvasEditor';
import {
  clearEditingImageData,
  draftEditImageData,
  resetEditingImageData,
  saveEditItemData,
} from '../../../application/CaseDesigner/CaseDesigner.slice';
import { EDITOR_BORDER_IN_PX } from '../../../domain/constants/ui';
import { ContainedBoxMetric } from '../../../domain/BackEnd.model';

type InplaceImageEditorProps = {
  editingImage: ImageData;
  displayRatio: number;
  phoneCaseExactBoundary: ContainedBoxMetric;
};
export const InplaceImageEditor: React.FC<InplaceImageEditorProps> = ({
  editingImage,
  phoneCaseExactBoundary,
  displayRatio,
}) => {
  const imageRef = useRef(null);
  const dispatch = useDispatch();
  useLayoutEffect(() => {
    if (!imageRef.current) {
      return;
    }
  }, [imageRef.current]);

  const onSaveChanges = (editedImageData: ImageData) => {
    dispatch(
      saveEditItemData({
        ...editedImageData,
      })
    );
  };
  const onDraftChange = (editedImageData: ImageData) => {
    dispatch(draftEditImageData({ ...editedImageData }));
  };
  const onClear = useCallback(() => {
    dispatch(clearEditingImageData());
  }, [dispatch]);
  const onReset = useCallback(() => {
    dispatch(resetEditingImageData());
  }, [dispatch]);
  const calculatedMetrics = useMemo<ContainedBoxMetric>(() => {
    return {
      width: phoneCaseExactBoundary.width * displayRatio,
      height: phoneCaseExactBoundary.height * displayRatio,
      x: -displayRatio - EDITOR_BORDER_IN_PX,
      y: -displayRatio - EDITOR_BORDER_IN_PX,
      borderTopLeftRadius:
        phoneCaseExactBoundary.borderTopLeftRadius * displayRatio,
      borderTopRightRadius:
        phoneCaseExactBoundary.borderTopRightRadius * displayRatio,
      borderBottomLeftRadius:
        phoneCaseExactBoundary.borderBottomLeftRadius * displayRatio,
      borderBottomRightRadius:
        phoneCaseExactBoundary.borderBottomRightRadius * displayRatio,
    };
  }, [displayRatio, phoneCaseExactBoundary]);
  return (
    <CanvasEditor
      calculatedMetric={calculatedMetrics}
      imageData={editingImage}
      onDraftChange={onDraftChange}
      onSave={onSaveChanges}
      onClear={onClear}
      onReset={onReset}
    />
  );
};
