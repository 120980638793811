import { PhoneCase } from '@qcases/common';
import { call, put, takeLatest } from 'redux-saga/effects';

import {
  fetchProducts,
  fetchProductsFailed,
  fetchProductsSuccess,
} from './ListingPage.slice';
import { getProducts } from './ListingPageApi.service';

// worker Saga: will be fired on USER_FETCH_REQUESTED actions
function* fetchProductProductsData(action) {
  try {
    const productDetail: PhoneCase[] = yield call(getProducts);
    yield put(fetchProductsSuccess(productDetail));
  } catch (e) {
    yield put(fetchProductsFailed((e as any).message as string));
  }
}

export function* listingPageSaga() {
  yield takeLatest(fetchProducts.type, fetchProductProductsData);
}
