import { PhoneCase } from '@qcases/common';
import axios from 'axios';
import { BASE_API_URL } from '../../domain/constants/api';


function getProductDetail(productAlias: string) {
  return axios
    .get<PhoneCase>(`${BASE_API_URL}/phone-cases/${productAlias}`)
    .then(res => res.data);
}

export { getProductDetail };
