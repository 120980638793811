import React from 'react';

export default function AppBanner({ title, subtitle }) {
  return (
    <div
      className="p-5 text-center bg-image"
      style={{
        backgroundImage: 'url(\'/assets/header-banner.webp\')',
        height: '400px',
        width: '100%',
      }}
    >
      <div className="mask" style={{ backgroundColor: 'rgba(0, 0, 0, 0.9)' }}>
        <div className="d-flex justify-content-center align-items-center h-100">
          <div className="text-white">
            <h1 className="mb-3">{title}</h1>
            <h4 className="mb-3">{subtitle}</h4>
          </div>
        </div>
      </div>
    </div>
  );
}
