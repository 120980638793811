
import { DragDropItemTypes } from '../../../domain/constants/ui';
import React, { useEffect } from 'react';
import { useDrag } from 'react-dnd';
import './AddedSticker.scss';
import { AddedSticker } from 'domain/BackEnd.model';

export const AddedStickerComp = React.memo<{
  sticker: AddedSticker;
  displayRatio: number;
  onDrag: (isDragging: boolean) => void;
}>(({ sticker, displayRatio, onDrag }) => {
  const { metric } = sticker;
  const [{ isDragging }, drag] = useDrag(
    () => ({
      type: DragDropItemTypes.ADDED_STICKER,
      item: sticker,
      collect: monitor => ({
        isDragging: !!monitor.isDragging(),
      }),
    }),
    [sticker]
  );

  useEffect(() => {
    onDrag(isDragging);
  }, [isDragging, onDrag]);
  return (
    <div
      ref={drag}
      className="AddedSticker"
      title="Drag to move"
      style={{
        left: `${metric.x * displayRatio}px`,
        top: `${metric.y * displayRatio}px`,
        width: `${metric.width * displayRatio}px`,
        height: `${metric.height * displayRatio}px`,
        borderBottomLeftRadius: `${
          metric.borderBottomLeftRadius * displayRatio
        }px`,
        borderBottomRightRadius: `${
          metric.borderBottomRightRadius * displayRatio
        }px`,
        borderTopLeftRadius: `${metric.borderTopLeftRadius * displayRatio}px`,
        borderTopRightRadius: `${metric.borderTopRightRadius * displayRatio}px`,
        opacity: isDragging ? 0 : 1,
      }}
    >
      <img
        className="AddedSticker__Image"
        src={sticker.stickerData.images['32']}
        alt={sticker.stickerData.description}
      />
    </div>
  );
});
AddedStickerComp.displayName = 'AddedStickerComp';
