import React, { SyntheticEvent } from 'react';
import { useDispatch } from 'react-redux';
import { editBackgroundImage } from '../../application/CaseDesigner/CaseDesigner.slice';
import { SquareItemProps } from './SquareItem.types';
import './SquareItem.scss';

// TODO: need to optimize performance
// when edit editingItem will affect the whole array of square items

export const SquareItem = React.memo<SquareItemProps>(
  // TODO: need to investigate later
  // eslint-disable-next-line react/prop-types
  ({ itemData, calculatedMetrics, isEditing }: SquareItemProps) => {
    const dispatch = useDispatch();
    const onSelectSquareItem = (
      event: SyntheticEvent<HTMLDivElement>
    ): void => {
      // stop propagation to avoid click event trigger on document.body
      // which is set event in CanvasEditor component
      event.stopPropagation();
      // Init edit mode here
      if (isEditing) {
        return;
      }
      dispatch(editBackgroundImage());
    };

    return (
      <div
        className="squareItem"
        style={{
          width: `${calculatedMetrics.width}px`,
          height: `${calculatedMetrics.height}px`,
          left: `${calculatedMetrics.x}px`,
          top: `${calculatedMetrics.y}px`,
          borderWidth: 0,
          borderTopLeftRadius: `${calculatedMetrics.borderTopLeftRadius}px`,
          borderTopRightRadius: `${calculatedMetrics.borderTopRightRadius}px`,
          borderBottomLeftRadius: `${calculatedMetrics.borderBottomLeftRadius}px`,
          borderBottomRightRadius: `${calculatedMetrics.borderBottomRightRadius}px`,
        }}
        onClick={onSelectSquareItem}
      >
        {!itemData.imageUrl || !itemData.outputBase64ImageUrl ? null : (
          <img
            width={calculatedMetrics.width}
            height={calculatedMetrics.height}
            src={itemData.outputBase64ImageUrl}
          />
        )}
      </div>
    );
  }
);
SquareItem.displayName = 'SquareItem';
