import React, { useEffect } from 'react';
import { IconSize } from '../../../domain/constants/iconSize';
import { Col } from 'react-bootstrap';
import './RawStickerItem.scss';
import { BsFillPlusCircleFill } from 'react-icons/bs';
import { DragDropItemTypes } from '../../../domain/constants/ui';
import { useDrag } from 'react-dnd';
import { Icon } from '@qcases/common';

const RawStickerItemInner: React.FC<{
  sticker: Icon;
  onAddIconClicked: (sticker: Icon) => void;
  onDrag: (isDragging: boolean) => void;
}> = ({ sticker, onDrag, onAddIconClicked }) => {
  const [{ isDragging }, drag] = useDrag(
    () => ({
      type: DragDropItemTypes.NEW_STICKER,
      item: sticker,
      collect: monitor => ({
        isDragging: !!monitor.isDragging(),
      }),
    }),
    [sticker]
  );

  useEffect(() => {
    onDrag(isDragging);
  }, [isDragging, onDrag]);
  const onAddIconClick = () => {
    onAddIconClicked(sticker);
  };

  return (
    <Col
      xl={4}
      className="RawStickerItem text-center mb-2"
      draggable
      ref={drag}
    >
      <img id={`${sticker.id}`} src={sticker.images[IconSize.L]} alt="" />
      <div
        style={{
          overflowX: 'auto',
          whiteSpace: 'nowrap',
          fontSize: '0.7em',
        }}
      >
        {sticker.description}
      </div>
      <BsFillPlusCircleFill
        className="AddStickerButton"
        size={'20px'}
        onClick={onAddIconClick}
        title="Add sticker"
      />
    </Col>
  );
};

export const RawStickerItem = React.memo(RawStickerItemInner);
