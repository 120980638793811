import { combineReducers } from 'redux';
import { caseDesignerReducer } from './CaseDesigner/CaseDesigner.slice';
import { iconListReducer } from './IconList/IconList.slice';
import { listingPageReducer } from './ListingPage/ListingPage.slice';
import { productDetailReducer } from './ProductDetail/ProductDetail.slice';
import { backgroundImageReducer } from './BackgroundImage/BackgroundImage.slice';

export const rootReducer = combineReducers({
  caseDesigner: caseDesignerReducer,
  productDetail: productDetailReducer,
  listingPage: listingPageReducer,
  iconList: iconListReducer,
  backgroundImage: backgroundImageReducer,
});

export type RootState = ReturnType<typeof rootReducer>;
