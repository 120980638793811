import React, { useEffect } from 'react';
import './App.scss';

import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { ProductDetail } from './view/pages/ProductDetail/ProductDetail';
import { ListingPage } from './view/pages/ListingPage';
import AppFooter from './view/_components/Footer';
import AppHeader from './view/_components/AppHeader';

export const App: React.FC = () => {
  useEffect(() => {
    console.log('App init');
  }, []);
  return (
    <>
      <AppHeader />
      <Router>
        <Switch>
          <Route exact path="/">
            <ListingPage />
          </Route>
          <Route exact path="/productDetails/:productName">
            <ProductDetail />
          </Route>
        </Switch>
      </Router>
      <AppFooter />
    </>
  );
};
