import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  AddedSticker,
  ContainedBoxMetric,
  Icon,
  StickerQueryModel,
} from 'domain/BackEnd.model';
import { uniqueId } from 'lodash';
import { Position } from 'domain/LayoutElement.model';
import { DEFAULT_ADDED_STICKER_SIZE } from '../../domain/constants/ui';

export interface IconListState {
  currentIcons: Icon[];
  loading: boolean;
  error: string;
  draggingSticker: boolean;
  addedStickers: AddedSticker[];
}

const initialState: IconListState = {
  currentIcons: [],
  loading: false,
  error: '',
  draggingSticker: false,
  addedStickers: [],
};
export const iconListSlice = createSlice({
  name: 'iconList',
  initialState,
  reducers: {
    fetchIcons: (state, action: PayloadAction<StickerQueryModel>) => {
      state.loading = true;
      state.error = '';
    },
    fetchIconsSuccess: (
      state,
      action: PayloadAction<{ icons: Icon[]; query: StickerQueryModel }>
    ) => {
      state.currentIcons = action.payload.icons;
      state.loading = false;
      state.error = '';
    },
    fetchIconsFailed: (state, action: PayloadAction<string>) => {
      state.loading = false;
      state.error = action.payload;
    },
    dragSticker: (state, action: PayloadAction<boolean>) => {
      state.draggingSticker = action.payload;
    },
    addSticker: (
      state,
      action: PayloadAction<{
        stickerId: number;
        exactBoundaryWidth: number;
        exactBoundaryHeight: number;
        camera: ContainedBoxMetric;
      }>
    ) => {
      const addedStickerData = state.currentIcons.find(
        s => s.id === action.payload.stickerId
      );
      if (!addedStickerData) {
        return;
      }
      const { camera, exactBoundaryWidth, exactBoundaryHeight } =
        action.payload;
      const cameraRight = camera.x + camera.width;
      const cameraBottom = camera.y + camera.height;
      const x =
        (exactBoundaryWidth - DEFAULT_ADDED_STICKER_SIZE) * Math.random();
      const y =
        (exactBoundaryHeight - DEFAULT_ADDED_STICKER_SIZE) * Math.random();
      const overlapXWithCamera = x < cameraRight && y < cameraBottom;
      const randomizedMetric: ContainedBoxMetric = {
        x: x,
        y: y + (!overlapXWithCamera ? 0 : cameraBottom),
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0,
        width: DEFAULT_ADDED_STICKER_SIZE,
        height: DEFAULT_ADDED_STICKER_SIZE,
      };
      state.addedStickers.push({
        clientSideId: uniqueId(),
        stickerData: addedStickerData,
        metric: randomizedMetric,
      });
      state.draggingSticker = false;
    },
    addStickerByDragDrop: (
      state,
      action: PayloadAction<{
        stickerId: number;
        position: Position;
      }>
    ) => {
      const addedStickerData = state.currentIcons.find(
        s => s.id === action.payload.stickerId
      );
      if (!addedStickerData) {
        return;
      }
      state.addedStickers.push({
        clientSideId: uniqueId(),
        stickerData: addedStickerData,
        metric: {
          x: action.payload.position.x,
          y: action.payload.position.y,
          borderBottomLeftRadius: 0,
          borderBottomRightRadius: 0,
          borderTopLeftRadius: 0,
          borderTopRightRadius: 0,
          width: 30,
          height: 30,
        },
      });
      state.draggingSticker = false;
    },
    modifyAddedSticker: (
      state,
      action: PayloadAction<{
        newAddedStickerData: AddedSticker;
      }>
    ) => {
      const changedStickerIndex = state.addedStickers.findIndex(
        s => s.clientSideId === action.payload.newAddedStickerData.clientSideId
      );
      if (changedStickerIndex < 0) {
        return;
      }

      state.addedStickers[changedStickerIndex] =
        action.payload.newAddedStickerData;
    },
  },
});

export const {
  fetchIcons,
  fetchIconsFailed,
  fetchIconsSuccess,
  dragSticker,
  addSticker,
  modifyAddedSticker,
  addStickerByDragDrop,
} = iconListSlice.actions;

export const { reducer: iconListReducer } = iconListSlice;
