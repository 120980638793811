import { Icon, StickerQueryModel, OrderBy } from '../../domain/BackEnd.model';
import axios from 'axios';
import { BASE_API_URL } from '../../domain/constants/api';

function getIcons(searchTerm: string) {
  const params: StickerQueryModel = {
    q: searchTerm,
    limit: '50',
    page: 1,
  };
  return axios
    .get<Icon[]>(`${BASE_API_URL}/icons/${OrderBy.priority}`, {
      params: params,
    })
    .then(res => res.data);
}

export { getIcons };
