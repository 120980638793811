import { Position } from "domain/LayoutElement.model";


export const checkIfIsElementClicked = (
  elementId: string,
  targetClickedElement: EventTarget
) => {
  const elementRef = document.getElementById(elementId);
  return (
    targetClickedElement === elementRef ||
    elementRef?.contains(targetClickedElement as any)
  );
};

export const calculateRelativePositionInPx = (
  targetElement: HTMLElement,
  baseElement: HTMLElement
) => {
  const targetBoundedRect = targetElement.getBoundingClientRect();
  const baseBoundedRect = baseElement.getBoundingClientRect();
  return {
    x: baseBoundedRect.x - targetBoundedRect.x,
    y: baseBoundedRect.y - targetBoundedRect.y,
  };
};

export const calculateRelativePositionByCoordinateInPx = (
  targetCoordinate: Position,
  baseElement: HTMLElement
) => {
  const baseBoundedRect = baseElement.getBoundingClientRect();
  return {
    x: targetCoordinate.x - baseBoundedRect.x,
    y: targetCoordinate.y - baseBoundedRect.y,
  };
};
