import React, { useCallback, useEffect, useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import {
  addSticker,
  dragSticker,
  fetchIcons,
  IconListState,
} from '../../../application/IconList/IconList.slice';
import { MDBInput } from 'mdb-react-ui-kit';
import _ from 'lodash';
import { RawStickerItem } from './RawStickerItem';
import './RawStickerList.scss';
import { ProductDetailState } from 'application/ProductDetail/ProductDetail.slice';
import { RootState } from 'application/root.reducer';
import { Icon, StickerQueryModel } from '@qcases/common';
import { CommonSpinner } from 'view/_components/CommonSpinner';

export const RawStickerList: React.FC = () => {
  const dispatch = useDispatch();
  const { currentIcons, loading, error } = useSelector<
    RootState,
    IconListState
  >(state => state.iconList);
  const { apiCall } = useSelector<RootState, ProductDetailState>(
    state => state.productDetail
  );
  const [searchTerm, setSearchTerm] = useState('');
  useEffect(() => {
    if (!searchTerm) {
      return;
    }
    dispatch(fetchIcons({ q: searchTerm } as StickerQueryModel));
  }, [dispatch, searchTerm]);
  const onSearchTermChange = _.debounce(event => {
    setSearchTerm(event.target.value);
  }, 1000);

  const onAddIconClicked = useCallback(
    (sticker: Icon) => {
      if (!apiCall.data) {
        console.error('Cannot add sticker when not finish loading phone case');
        return;
      }
      const { exactBoundary, camera } = apiCall.data;
      if (!exactBoundary) {
        return;
      }
      dispatch(
        addSticker({
          stickerId: sticker.id,
          exactBoundaryWidth: exactBoundary.width,
          exactBoundaryHeight: exactBoundary.height,
          camera,
        })
      );
    },
    [apiCall.data, dispatch]
  );
  const onStickerDragging = useCallback(
    (isDragging: boolean) => {
      dispatch(dragSticker(isDragging));
    },
    [dispatch]
  );
  return (
    <Container className="iconList pb-4">
      <h2>Add stickers</h2>
      <Row className="iconList__searchArea mb-4">
        <MDBInput label={'Search stickers'} onChange={onSearchTermChange} />
      </Row>
      <Row className="iconList__stickerList pb-4">
        <Col>
          {loading ? (
            <CommonSpinner />
          ) : error ? (
            'Error'
          ) : (
            <Container fluid>
              <Row>
                {!currentIcons || !currentIcons.length
                  ? 'Sticker list'
                  : currentIcons.map(sticker => {
                      return (
                        <RawStickerItem
                          key={sticker.id}
                          sticker={sticker}
                          onDrag={onStickerDragging}
                          onAddIconClicked={onAddIconClicked}
                        />
                      );
                    })}
              </Row>
            </Container>
          )}
        </Col>
      </Row>
    </Container>
  );
};
