import { spawn } from '@redux-saga/core/effects';
import { iconListSaga } from './IconList/IconList.saga';
import { listingPageSaga } from './ListingPage/ListingPage.saga';
import { productDetailSaga } from './ProductDetail/ProductDetail.saga';
import { backgroundImageSaga } from './BackgroundImage/BackgroundImage.saga';

export function* productDetailRootSaga() {
  yield spawn(productDetailSaga);
  yield spawn(listingPageSaga);
  yield spawn(iconListSaga);
  yield spawn(backgroundImageSaga);
}
