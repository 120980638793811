import { configureStore } from "@reduxjs/toolkit";
import { rootReducer } from "./application/root.reducer";
import createSagaMiddleware from "redux-saga";
import { productDetailRootSaga } from "./application/root.saga";
// create the saga middleware
const sagaMiddleware = createSagaMiddleware();

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(sagaMiddleware),
});
sagaMiddleware.run(productDetailRootSaga);

export { store };
