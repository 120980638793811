
import { DragDropItemTypes } from '../../../domain/constants/ui';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  addStickerByDragDrop,
  IconListState,
  modifyAddedSticker,
} from '../../../application/IconList/IconList.slice';
import './StickerDropArea.scss';
import { useDrop } from 'react-dnd';
import { calculateRelativePositionByCoordinateInPx } from 'utils/dom';
import { ContainedBoxMetric, Icon } from '@qcases/common';
import { RootState } from 'application/root.reducer';
import { AddedSticker } from 'domain/BackEnd.model';

export const StickerDropArea: React.FC<{
  calculatedMetrics: ContainedBoxMetric;
  displayRatio: number;
}> = ({ calculatedMetrics, displayRatio }) => {
  const { draggingSticker } = useSelector<RootState, IconListState>(
    state => state.iconList
  );
  const dispatch = useDispatch();
  const [{ isOver }, drop] = useDrop(
    () => ({
      accept: [DragDropItemTypes.ADDED_STICKER, DragDropItemTypes.NEW_STICKER],
      drop: (sticker: AddedSticker | Icon, monitor) => {
        if (monitor.getItemType() === DragDropItemTypes.ADDED_STICKER) {
          sticker = sticker as AddedSticker;
          const differencedCoordinate =
            monitor.getDifferenceFromInitialOffset();
          if (!differencedCoordinate) {
            return;
          }
          const { x, y } = differencedCoordinate;
          dispatch(
            modifyAddedSticker({
              newAddedStickerData: {
                ...sticker,
                metric: {
                  ...sticker.metric,
                  x: sticker.metric.x + x / displayRatio,
                  y: sticker.metric.y + y / displayRatio,
                },
              },
            })
          );
        } else if (monitor.getItemType() === DragDropItemTypes.NEW_STICKER) {
          sticker = sticker as Icon;
          let clientOffset = monitor.getSourceClientOffset();
          const defaultOffset = { x: 0, y: 0 };
          clientOffset = clientOffset === null ? defaultOffset : clientOffset;
          const stickerPos = calculateRelativePositionByCoordinateInPx(
            clientOffset,
            document.getElementById('stickerDropArea')!
          );
          dispatch(
            addStickerByDragDrop({
              stickerId: sticker.id,
              position: stickerPos,
            })
          );
        }
      },
      collect: monitor => ({
        isOver: !!monitor.isOver(),
      }),
    }),
    [displayRatio]
  );
  return (
    <div
      hidden={!draggingSticker}
      id="stickerDropArea"
      className="stickerDropArea"
      ref={drop}
      style={{
        width: `${calculatedMetrics.width}px`,
        height: `${calculatedMetrics.height}px`,
        left: `${calculatedMetrics.x}px`,
        top: `${calculatedMetrics.y}px`,
        borderWidth: 0,
        borderTopLeftRadius: `${calculatedMetrics.borderTopLeftRadius}px`,
        borderTopRightRadius: `${calculatedMetrics.borderTopRightRadius}px`,
        borderBottomLeftRadius: `${calculatedMetrics.borderBottomLeftRadius}px`,
        borderBottomRightRadius: `${calculatedMetrics.borderBottomRightRadius}px`,
        outlineColor: 'red',
        outlineWidth: '1px',
        outlineStyle: 'solid',
        backgroundColor: isOver ? 'rgba(0,0,0,0.4)' : 'transparent',
      }}
    ></div>
  );
};
