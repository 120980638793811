import React, { useEffect } from 'react';
import { Button, Card, Col, Container, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { fetchProducts } from '../../application/ListingPage/ListingPage.slice';
import AppBanner from '../_components/AppBanner';
import { RootState } from 'application/root.reducer';
import { PhoneCase } from '@qcases/common';
import { HttpRequest } from 'domain/HttpRequest.model';

export const ListingPage: React.FC = () => {
  const dispatch = useDispatch();
  const { loading, data, error } = useSelector<
    RootState,
    HttpRequest<PhoneCase[], any>
  >(state => state.listingPage.apiCall);
  const { push } = useHistory();
  useEffect(() => {
    dispatch(fetchProducts());
  }, []);
  const onProductClick = (product: PhoneCase) => {
    push(`productDetails/${product.alias}`);
  };
  if (loading) {
    return <>Loading</>;
  }
  if (error) {
    return <div>Error page</div>;
  }
  return (
    <>
      <AppBanner title={'Phone cases'} subtitle={'Phone cases list'} />
      <Container className="pb-4 pt-4">
        <Row>
          {data?.map(product => {
            return (
              <Col key={product.id} xl="3">
                <Card>
                  <Card.Img variant="top" src={product.imageUrl} />
                  <Card.Body>
                    <Card.Title>{product.name}</Card.Title>
                    <Card.Text>A good phone case for customize.</Card.Text>
                    <Button
                      variant="primary"
                      onClick={() => onProductClick(product)}
                    >
                      Go to detail
                    </Button>
                  </Card.Body>
                </Card>
              </Col>
            );
          })}
        </Row>
      </Container>
    </>
  );
};
