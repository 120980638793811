import React, { useEffect, useRef } from 'react';
import { Button } from 'react-bootstrap';
import './CaseDesigner.scss';
import { DesignerPad } from './DesignerPad';
import { useDispatch } from 'react-redux';
import { turnOffEditBackgroundImage } from '../application/CaseDesigner/CaseDesigner.slice';
import { PhoneCase } from '@qcases/common';
// import html2canvas from 'html2canvas';
import domtoimage from 'dom-to-image';

export const CaseDesigner: React.FC<{
  phoneCase: PhoneCase;
}> = ({ phoneCase }) => {
  const { exactBoundary } = phoneCase;
  const dispatch = useDispatch();
  const exportResultRef = useRef(null);
  useEffect(() => {
    return () => {
      // clear editing item
      dispatch(turnOffEditBackgroundImage());
    };
  }, []);

  const onExportDesignClicked = () => {
    if (exportResultRef.current === null) {
      return;
    }
    // not full image as shown now, should investigate https://github.com/tsayen/dom-to-image
    domtoimage
      .toBlob(document.querySelector('.outputPhoneCasePreview__exactBoundary')!)
      .then(function (blob) {
        const a = document.createElement('a');
        document.body.appendChild(a);
        a.style.display = 'none';
        const url = window.URL.createObjectURL(blob);
        a.href = url;
        a.download = 'test-file.png';
        a.click();
        window.URL.revokeObjectURL(url);
      });
  };
  return (
    <div id="caseDesigner" className="caseDesigner">
      <div className="mb-4"></div>
      {exactBoundary.width && exactBoundary.height && (
        <>
          <DesignerPad
            phoneCase={phoneCase}
            exportResultRef={exportResultRef}
          />
          <div className="mb-4"></div>
          <Button onClick={onExportDesignClicked}>Export design</Button>
        </>
      )}
    </div>
  );
};
